<template>
  <app-action-btn
    wrapper-class="mb-0 small-p d-flex align-center hover-effect pointer flex-sm-row flex-column"
    :access="canModifyTableRows(role)"
    show-tooltip
    top
    data-test="add_new_row_button"
    @click="canModifyTableRows(role).allowed && createEmptyRow({
      tableId,
      tableType,
      projectId: $route.params.id,
    })">
    <template #iconWrapper>
      <v-icon class="mr-2 icon-wrapper">
        mdi-plus
      </v-icon>
    </template>
    <span>
      Add new row
    </span>
  </app-action-btn>
</template>
<script>
import {
  mapActions, mapState, mapGetters,
} from 'vuex';
export default {
  name: 'TableAddNewRow',
  props: {
    tableId: {
      type: String,
      default: null,
    },
    tableType: {
      type: String,
      default: 'schedule',
    },
  },
  computed: {
    ...mapState(['role']),
    ...mapGetters('UserRoles', ['canModifyTableRows']),
  },
  methods: {
    ...mapActions('ScheduleRows', ['createEmptyRow']),
  },
};
</script>
<style scoped lang="scss">
  .icon-wrapper{
    background: linear-gradient(270deg, #71D6E0 -42.03%, #0AA8DA 134.48%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    // transform: matrix(1, 0, 0, -1, 0, 0);
    color:#FFFFFF ;
  }
</style>
